var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"main-container"},[_vm._l((_vm.errorMessages),function(message){return _c('div',{key:message,staticClass:"alert alert-danger mb-0"},[_vm._v(" "+_vm._s(message)+" "),_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.dismissErrorMessage(message)}}},[_vm._v(" X ")])])}),(!_vm.store.loading)?_c('div',[(_vm.selectedSubscription)?_c('div',[_c('SubscriptionDetails',{attrs:{"subscription":_vm.selectedSubscription},on:{"toggle-contentwatched":_vm.handleToggleContentWatched,"play-content":_vm.handlePlayContentEvent,"download":_vm.handleDownloadContent,"download-delete":_vm.handleDeleteDownloadContent,"toggle-includeinfeed":function($event){return _vm.handleToggleIncludeInFeed(_vm.selectedSubscription)},"toggle-shownotifications":function($event){return _vm.handleToggleShowNotifications(_vm.selectedSubscription)}}}),_c('button',{staticClass:"btn btn-outline-secondary float-left back-button",on:{"click":_vm.subscriptionBackButtonPressed}},[_vm._v(" < ")])],1):_c('div',_vm._l((_vm.subscriptions),function(subscription){return _c('button',{key:subscription.rssUrl,staticClass:"list-group-item list-group-item-action",class:{
                        'list-group-item-secondary':
                            !subscription.showNotifications,
                        'list-group-item-success': subscription.watching,
                    },on:{"click":function($event){return _vm.subscriptionListButtonPressed(subscription)}}},[(
                        subscription.unwatchedCount > 0 &&
                        subscription.showNotifications
                    )?_c('button',{staticClass:"btn btn-sm btn-success float-left",on:{"click":function($event){$event.stopPropagation();return _vm.handlePlayNextButtonPressed(subscription)}}},[_c('span',{staticClass:"fas fa-play"})]):_vm._e(),(
                        subscription.allUnwatchedCount >
                        subscription.unwatchedCount &&
                        subscription.showNotifications
                    )?_c('button',{staticClass:"btn btn-sm btn-warning float-left",on:{"click":function($event){$event.stopPropagation();return _vm.handlePlayReverseButtonPressed(subscription)}}},[_c('span',{staticClass:"fas fa-play"})]):_vm._e(),_vm._v(" "+_vm._s(subscription.title)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(subscription.showNotifications &&
                        subscription.unwatchedCount
                        ),expression:"subscription.showNotifications &&\n                        subscription.unwatchedCount\n                        "}],staticClass:"badge badge-pill badge-info float-right"},[_vm._v(" "+_vm._s(subscription.unwatchedCount)+" ")])])}),0)]):_vm._e(),(_vm.isLoading)?_c('div',[_vm._v("Subscriptions are loading...")]):_vm._e()],2),_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"player-container"},[(_vm.playingContent)?_c('ContentPlayer',{attrs:{"content":_vm.playingContent,"enableAutoplayButton":true,"enableNextButton":true,"enablePreviousButton":true},on:{"completed":_vm.handlePlayerCompleted,"autoplay-next":_vm.handleAutoplayNext,"skip-next":_vm.handlePlayerSkipNext,"skip-previous":_vm.handlePlayerSkipPrevious,"duration-changed":_vm.handlePlayerDurationChanged,"progress-changed":_vm.handlePlayerProgressChanged}}):_vm._e()],1)]),_c('div',{staticClass:"spacer"})])}
var staticRenderFns = []

export { render, staticRenderFns }
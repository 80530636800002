<template>
    <div class="page-container">
        <div class="main-container">
            <div class="alert alert-danger mb-0" v-for="message in errorMessages" :key="message">
                {{ message }}
                <button class="btn btn-danger" @click="dismissErrorMessage(message)">
                    X
                </button>
            </div>

            <div v-if="!store.loading">
                <div v-if="selectedSubscription">
                    <SubscriptionDetails :subscription="selectedSubscription"
                        @toggle-contentwatched="handleToggleContentWatched" @play-content="handlePlayContentEvent"
                        @download="handleDownloadContent" @download-delete="handleDeleteDownloadContent"
                        @toggle-includeinfeed="
                            handleToggleIncludeInFeed(selectedSubscription)
                            " @toggle-shownotifications="
                            handleToggleShowNotifications(selectedSubscription)
                            " />
                    <button class="btn btn-outline-secondary float-left back-button"
                        @click="subscriptionBackButtonPressed">
                        &lt;
                    </button>
                </div>
                <div v-else>
                    <button class="list-group-item list-group-item-action" v-for="subscription in subscriptions"
                        :key="subscription.rssUrl" @click="subscriptionListButtonPressed(subscription)" :class="{
                            'list-group-item-secondary':
                                !subscription.showNotifications,
                            'list-group-item-success': subscription.watching,
                        }">
                        <button v-if="
                            subscription.unwatchedCount > 0 &&
                            subscription.showNotifications
                        " class="btn btn-sm btn-success float-left" @click.stop="
                                handlePlayNextButtonPressed(subscription)
                                ">
                            <span class="fas fa-play"></span>
                        </button>

                        <button v-if="
                            subscription.allUnwatchedCount >
                            subscription.unwatchedCount &&
                            subscription.showNotifications
                        " class="btn btn-sm btn-warning float-left" @click.stop="
                                handlePlayReverseButtonPressed(subscription)
                                ">
                            <span class="fas fa-play"></span>
                        </button>

                        {{ subscription.title }}

                        <span v-show="subscription.showNotifications &&
                            subscription.unwatchedCount
                            " class="badge badge-pill badge-info float-right">
                            {{ subscription.unwatchedCount }}
                        </span>
                    </button>
                </div>
            </div>
            <div v-if="isLoading">Subscriptions are loading...</div>
        </div>
        <div class="footer-container">
            <div class="player-container">
                <ContentPlayer v-if="playingContent" :content="playingContent" :enableAutoplayButton="true"
                    :enableNextButton="true" :enablePreviousButton="true" @completed="handlePlayerCompleted"
                    @autoplay-next="handleAutoplayNext" @skip-next="handlePlayerSkipNext"
                    @skip-previous="handlePlayerSkipPrevious" @duration-changed="handlePlayerDurationChanged"
                    @progress-changed="handlePlayerProgressChanged" />
            </div>
        </div>
        <div class="spacer"></div>
    </div>
</template>

<script>
import SubscriptionStore, {
    SubscriptionModel,
} from "../scripts/SubscriptionStore";
import SubscriptionDetails from "../components/SubscriptionDetails.vue";
import ContentPlayer from "../components/ContentPlayer.vue";

export default {
    props: {
        store: SubscriptionStore,
    },
    components: { SubscriptionDetails, ContentPlayer },
    data: () => ({
        /** @type {String[]} */
        errorMessages: [],
        /** @type {SubscriptionModel[]} */
        subscriptions: [],
        selectedSubscription: null,
        isLoading: true,
        test: "",
        playingContent: null,
    }),
    async mounted() {
        /** @type {SubscriptionStore} */
        let _store = this.store;
        await _store.populateSubscriptions();
        this.notifyStoreErrors();

        let subscriptions = _store.getSubscriptions();
        this.subscriptions.push(...subscriptions);
        this.isLoading = false;

        if (this.store.playingContent) {
            this.loadContentIntoPlayer(this.store.playingContent);
        }
    },
    methods: {
        dismissErrorMessage(message) {
            let index = this.errorMessages.indexOf(message);
            if (index > -1) {
                this.errorMessages.splice(index, 1);
            }
        },
        notifyStoreErrors() {
            var errors = this.store.harvestErrors();
            errors.forEach(e => this.errorMessages.push(e));
        },
        async handlePlayReverseButtonPressed(subscription) {
            let content = await this.store.getLastUnwatchedContent(
                subscription.rssUrl
            );
            this.notifyStoreErrors();
            if (content) {
                this.loadContentIntoPlayer(content, subscription);
            }
        },
        async handlePlayNextButtonPressed(subscription) {
            let content = await this.store.getFirstUnwatchedContent(
                subscription.rssUrl
            );
            this.notifyStoreErrors();
            if (content) {
                this.loadContentIntoPlayer(content, subscription);
            }
        },
        async subscriptionListButtonPressed(subscription) {
            this.subscriptionScrollingPosition = document.documentElement.scrollTop;
            this.selectedSubscription = subscription;
            await this.store.getSubscriptionContentList(subscription.rssUrl); //kicks off the loading of the subscription
            this.notifyStoreErrors();
            if (this.selectedSubscription != null) {
                //cause there may be pause enough for the user to back out
                setTimeout(() => {
                    let playing = this.$el.querySelector(".list-group-item-success");

                    if (playing) {
                        playing.scrollIntoView({ behavior: "smooth" });
                    } else {
                        document.documentElement.scrollTo(0, 0);
                    }
                }, 100);
            }
        },
        subscriptionBackButtonPressed() {
            this.selectedSubscription = null;
            if (this.subscriptionScrollingPosition) {
                document.documentElement.scrollTo(0, this.subscriptionScrollingPosition);
                this.subscriptionScrollingPosition = null;
            } else {
                document.documentElement.scrollTo(0, 0);
            }
        },
        async handlePlayContentEvent(content) {
            this.loadContentIntoPlayer(content, this.selectedSubscription);
        },
        async handleToggleContentWatched(content) {
            if (content.watched)
                await this.store.markContentUnWatched(
                    content.rssUrl,
                    content.title,
                    content.guid,
                    content.url
                );
            else
                await this.store.markContentWatched(
                    content.rssUrl,
                    content.title,
                    content.guid,
                    content.url
                );
            this.notifyStoreErrors();
        },
        async handleDownloadContent(content) {
            await this.store.downloadContent(
                content.rssUrl,
                content.title,
                content.url,
                content.guid
            );
            this.notifyStoreErrors();
        },
        async handleDeleteDownloadContent(content) {
            await this.store.deleteDownloadedContent(
                content.rssUrl,
                content.title,
                content.url,
                content.guid
            );
            this.notifyStoreErrors();
        },
        async handleToggleIncludeInFeed(subscription) {
            await this.store.updateSubscriptionPreferences(
                subscription.rssUrl,
                !subscription.includeInFeed,
                subscription.showNotifications
            );
            this.notifyStoreErrors();
        },
        async handleToggleShowNotifications(subscription) {
            await this.store.updateSubscriptionPreferences(
                subscription.rssUrl,
                subscription.includeInFeed,
                !subscription.showNotifications
            );
            this.notifyStoreErrors();
        },

        async loadContentIntoPlayer(content, subscription) {
            let playerContent =
                await this.store.getContentForPlayerAndMarkAsPlaying(
                    subscription?.rssUrl ?? content.rssUrl,
                    content.title,
                    content.url,
                    content.guid,
                    content.progress,
                    content.duration
                );
            this.playingContent = playerContent;
        },

        handlePlayerCompleted() {
            this.store.markContentWatched(
                this.playingContent.rssUrl,
                this.playingContent.title,
                this.playingContent.guid,
                this.playingContent.url
            );
            this.notifyStoreErrors();
        },
        async handlePlayerSkipNext() {
            let nextContent = this.store.getNextContent(
                this.playingContent.rssUrl,
                this.playingContent.title,
                this.playingContent.url,
                this.playingContent.guid
            );
            if (nextContent) {
                let playingSub = this.store.getPlayingSubscription();
                this.loadContentIntoPlayer(nextContent, playingSub);
            }
            this.notifyStoreErrors();
        },
        handleAutoplayNext() {
            this.handlePlayerSkipNext();
        },
        async handlePlayerSkipPrevious() {
            let previousContent = this.store.getPreviousContent(
                this.playingContent.rssUrl,
                this.playingContent.title,
                this.playingContent.url,
                this.playingContent.guid
            );
            if (previousContent) {
                let playingSub = this.store.getPlayingSubscription();
                this.loadContentIntoPlayer(previousContent, playingSub);
            }
        },
        handlePlayerDurationChanged(duration) {
            this.playingContent.duration = duration; //meh
        },
        handlePlayerProgressChanged(progress, duration) {
            this.store.handlePlayerProgressMade(
                this.store.getPlayingSubscription()?.rssUrl ??
                this.playingContent.rssUrl,
                this.playingContent.title,
                this.playingContent.url,
                this.playingContent.guid,
                progress,
                duration
            );
        },
    },
};
</script>

<style scoped>
/* .footer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  max-height: 12em;
} */

/*.page-container {
    height: 100vh;
    width: 100vw;
}*/

.main-container {
    overflow-x: hidden;
}

.footer-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 1;
}

.player-container {
    border-top: 1px solid black;
}

.spacer {
    min-height: 12em;
}
</style>
